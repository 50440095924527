<template>
  <div>
    <v-card class="ma-1 pa-1">
      <v-card-title class="pa-1">
        <span>{{ $t("Inventarizatsiya") }}</span>
        <v-spacer></v-spacer>
        <v-btn
          outlined
          small
          @click="
            getInventoryExcel(1);
            inventory_excel = [];
          "
          class="mr-2"
          color="success"
        >
          <!-- <span style="color: #4caf50">MC EXCEL</span> -->
          Excelga yuklab olish
        </v-btn>
        <div v-if="$store.getters.checkRole('inventory_operator')">
          <span>{{ $t("Soni: ") }}</span>
          <v-btn class="mx-1" outlined @click="changeProductsCount(1)" small
            >1</v-btn
          >
          <v-btn class="mx-1" outlined @click="changeProductsCount(2)" small
            >2</v-btn
          >
          <v-btn class="mx-1" outlined @click="changeProductsCount(3)" small
            >3</v-btn
          >
          <v-btn class="mx-1" outlined @click="changeProductsCount(4)" small
            >4</v-btn
          >
          <v-btn class="mx-1" outlined @click="changeProductsCount(5)" small
            >5</v-btn
          >
          <v-btn class="mx-1" outlined @click="changeProductsCount(6)" small
            >6</v-btn
          >
        </div>
      </v-card-title>
      <v-card-text
        class="pb-1"
        v-if="$store.getters.checkRole('inventory_operator')"
      >
        <v-form ref="inventorCreateForm">
          <v-row>
            <v-col class="pa-1" cols="3">
              <v-autocomplete
                :label="$t('Komissiya')"
                class="pa-0"
                v-model="formInv.inventory_commission_id"
                :items="commissions"
                :rules="[(v) => !!v || $t('input.required')]"
                hide-details
                dense
                outlined
                @change="getWarehouses()"
                id="inventory_commission_id"
              >
              </v-autocomplete>
            </v-col>
            <v-col class="pa-1" cols="3">
              <v-autocomplete
                :label="$t('Ombor')"
                class="pa-0"
                v-model="formInv.warehouse_id"
                :items="warehouses"
                item-value="id"
                item-text="name"
                :rules="[(v) => !!v || $t('input.required')]"
                hide-details
                dense
                :disabled="formInv.inventory_commission_id ? false : true"
                outlined
                id="idWarehouses"
              >
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                    <v-list-item-subtitle
                      v-text="item.code"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col class="pa-1" cols="3">
              <v-text-field
                :label="$t('Blanka sanasi')"
                v-model="formInv.blank_date"
                :rules="[(v) => !!v || $t('input.required')]"
                hide-details
                outlined
                type="date"
                dense
              ></v-text-field>
            </v-col>
            <v-col class="pa-1" cols="3">
              <v-text-field
                :label="$t('Blanka nomeri')"
                v-model="formInv.blank_number"
                :rules="[(v) => !!v || $t('input.required')]"
                hide-details
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col class="pa-1" cols="3">
              <v-text-field
                :label="$t('Part nomeri')"
                v-model="formInv.part_number"
                @keyup="getProduct()"
                :rules="[
                  (v) => !!v || $t('input.required'),
                  (v) =>
                    (v && v.length > 7) || 'Part NO 8 hona bo\'lishi shart!!!',
                  (v) =>
                    (v && v.length < 9) || 'Part NO 8 hona bo\'lishi shart!!!',
                ]"
                hide-details="auto"
                max="8"
                outlined
                :disabled="formInv.warehouse_id ? false : true"
                dense
                id="part_number"
              ></v-text-field>
            </v-col>
            <v-col class="pa-1" cols="4">
              <v-text-field
                :label="$t('Material(butlovchi qism)')"
                v-model="formInv.product_name"
                hide-details
                :disabled="true"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col class="pa-1" cols="3">
              <v-autocomplete
                :label="$t('O\'lchov')"
                class="pa-0"
                v-model="formInv.unit_measure"
                :items="unitMeasures"
                :rules="[(v) => !!v || $t('input.required')]"
                hide-details
                dense
                outlined
                id="unit_measure"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="4"
              class="pa-1"
              v-for="(product, index) in formInv.products"
              :key="index"
            >
              <v-card class="pa-1" outlined>
                <v-card-text>
                  <v-row>
                    <v-col class="py-0 pr-1">
                      <v-autocomplete
                        :label="$t('Adres ') + (index + 1)"
                        class="pa-0"
                        clearable
                        v-model="product.inventory_address_id"
                        :items="inventoryAddresses"
                        :rules="[
                          (v) => !!v || index != 0 || $t('input.required'),
                        ]"
                        hide-details
                        dense
                        @change="changeAddress(product.inventory_address_id)"
                        outlined
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col class="py-0 pl-1" cols="5">
                      <v-text-field
                        :label="$t('Aniqlangan soni ') + (index + 1)"
                        v-model="product.real_stock"
                        :rules="[
                          (v) => !!v || index != 0 || $t('input.required'),
                        ]"
                        hide-details
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col class="pa-1" cols="3" lg="2" xl="1">
              <v-btn color="success" @click="save()">Saqlash</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-data-table
        dense
        fixed-header
        :loading-text="$t('loadingText')"
        :no-data-text="$t('noDataText')"
        :height="screenHeight"
        :loading="loading"
        :headers="headers"
        :items="items"
        :search="search"
        class="ma-1"
        style="border: 1px solid #aaa"
        item-key="id"
        :server-items-length="server_items_length"
        :options.sync="dataTableOptions"
        :disable-pagination="true"
        :footer-props="{
          itemsPerPageOptions: [20, 50, 100],
          itemsPerPageAllText: $t('itemsPerPageAllText'),
          itemsPerPageText: $t('itemsPerPageText'),
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-arrow-left',
          nextIcon: 'mdi-arrow-right',
        }"
        @update:page="updatePage"
        @update:items-per-page="updatePerPage"
      >
        <template v-slot:body.prepend>
          <tr class="py-0 my-0">
            <td class="py-0 my-0 dense"></td>
            <td class="py-0 my-0 dense">
              <v-autocomplete
                class="py-0"
                clearable
                v-model="filter.warehouse_id"
                :items="filter_warehouses"
                dense
                hide-details
                item-value="id"
                @change="getList()"
              >
                <template v-slot:selection="{ item }">{{ item.name }}</template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </td>
            <td class="py-0 my-0 dense">
              <v-autocomplete
                class="py-0"
                clearable
                v-model="filter.commission_id"
                :items="commissions"
                dense
                hide-details
                @change="getList()"
              >
              </v-autocomplete>
            </td>
            <td class="py-0 my-0 dense">
              <v-text-field
                v-model="filter.blank_date"
                type="date"
                hide-details
                @keyup.native.enter="getList()"
                clearable
              ></v-text-field>
            </td>
            <td class="py-0 my-0 dense">
              <v-text-field
                v-model="filter.blank_number"
                hide-details
                @keyup.native.enter="getList()"
                clearable
              ></v-text-field>
            </td>
            <td class="py-0 my-0 dense">
              <v-text-field
                v-model="filter.part_number"
                hide-details
                @keyup.native.enter="getList()"
                clearable
              ></v-text-field>
            </td>
            <td class="py-0 my-0 dense">
              <v-text-field
                v-model="filter.real_stock"
                hide-details
                @keyup.native.enter="getList()"
                clearable
              ></v-text-field>
            </td>
            <td class="py-0 my-0 dense">
              <v-autocomplete
                clearable
                v-model="filter.inventory_address_id"
                :items="inventoryAddresses"
                hide-details
                dense
                @change="getList()"
              >
              </v-autocomplete>
            </td>
            <td class="py-0 my-0 dense">
              <v-text-field
                v-model="filter.created_employee"
                type="text"
                hide-details
                @keyup.native.enter="getList()"
                clearable
              ></v-text-field>
            </td>
            <td class="py-0 my-0 dense">
              <v-text-field
                v-model="filter.checked_employee"
                type="text"
                hide-details
                @keyup.native.enter="getList()"
                clearable
              ></v-text-field>
            </td>
            <td class="py-0 my-0 dense"></td>
          </tr>
        </template>
        <template v-slot:item="{ item }">
          <tr :class="item.blank_status == 1 ? '' : 'red lighten-4 '">
            <td>
              {{
                items
                  .map(function (x) {
                    return x.id;
                  })
                  .indexOf(item.id) + from
              }}
            </td>
            <td>{{ item.warehouse.name }}</td>
            <td>{{ item.commission.commission_number }}</td>
            <td>{{ item.blank_date }}</td>
            <td>{{ item.blank_number }}</td>
            <td>{{ item.part_number }}</td>
            <td>{{ item.real_stock + " (" + item.unit_measure + ")" }}</td>
            <td>{{ item.address ? item.address.address_name : "" }}</td>
            <td>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">{{
                    item.created_employee
                  }}</span>
                </template>
                <span>
                  {{ item.created_at }}
                </span>
              </v-tooltip>
            </td>
            <td>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">{{
                    item.checked_employee
                  }}</span>
                </template>
                <span>{{ item.checked_at }}</span>
              </v-tooltip>
            </td>
            <td>
              <v-btn
                v-if="
                  ($store.getters.checkRole('inventory_operator') &&
                    !item.checked_at) &&
                  item.blank_status == 1
                "
                color="blue"
                small
                icon
                @click="editItem(item)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                v-if="
                  $store.getters.checkRole('inventory_controller') &&
                  !item.checked_at &&
                  item.blank_status == 1
                "
                color="green"
                small
                icon
                @click="check(item)"
              >
                <v-icon>mdi-check</v-icon>
              </v-btn>
              <v-btn
                v-if="
                  $store.getters.checkRole('inventory_blank_status_change') &&
                  item.blank_status == 1
                "
                color="red"
                small
                icon
                @click="changeStatusItem(item)"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="downloadExcel" hide-overlay persistent width="300">
      <v-card>
        <v-card-text class="py-1 px-3">
          <v-btn
            color="success"
            class="mx-10"
            @click="downloadExcel = false"
            text
          >
            <download-excel
              :data="inventory_excel"
              :name="'Inv_ruyxati_' + today + '.xls'"
            >
              <span style="color: #4caf50">{{ $t("download") }}</span>
              <v-icon color="success" height="20">mdi-download</v-icon>
            </download-excel>
          </v-btn>
          <v-btn class color="error" @click="downloadExcel = false" icon>
            <v-icon color="error" height="20">mdi-close</v-icon>
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="loading" width="300" hide-overlay>
      <v-card color="primary" dark>
        <v-card-text>
          {{ $t("loadingText") }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
const axios = require("axios").default;
const moment = require("moment");
import Swal from "sweetalert2";
import Template from "../document/Template.vue";
export default {
  components: { Template },
  data() {
    return {
      formInv: {
        id: 0,
        blank_number: null,
        blank_date: moment().format("YYYY-MM-DD"),
        unit_measure: "sht",
        warehouse_id: null,
        product_count: 6,
        part_number: null,
        commission_id: null,
        product_name: null,
        products: [
          {
            real_stock: null,
            inventory_address_id: null,
          },
          {
            real_stock: null,
            inventory_address_id: null,
          },
          {
            real_stock: null,
            inventory_address_id: null,
          },
          {
            real_stock: null,
            inventory_address_id: null,
          },
          {
            real_stock: null,
            inventory_address_id: null,
          },
          {
            real_stock: null,
            inventory_address_id: null,
          },
        ],
      },
      warehouses: [],
      inventoryAddresses: [],
      commissions: [],
      search: "",
      isLoading: false,
      loading: false,
      items: [],
      dataTableOptions: { page: 1, itemsPerPage: 20 },
      page: 1,
      from: 0,
      server_items_length: -1,
      errorEmpMessage: true,
      unitMeasures: ["sht", "kg", "litr", "metr", "p.metr"],
      datatime: "",
      filter: {},
      filter_warehouses: [],
      inventory_excel: [],
      downloadExcel: false,
      today: moment().format("YYYY-MM-DD"),
    };
  },
  computed: {
    screenHeight() {
      return window.innerHeight - 250;
    },
    headers() {
      return [
        { text: "#", value: "id", align: "center", width: 30, sortable: false },
        {
          text: this.$t("Ombor"),
          value: "warehouse.name",
          sortable: false,
        },
        {
          text: this.$t("Komissiya"),
          value: "commission.commission_number",
          sortable: false,
        },
        {
          text: this.$t("Blanka sanasi"),
          value: "blank_date",
          sortable: false,
        },
        {
          text: this.$t("Blanka raqami"),
          value: "blank_number",
          sortable: false,
        },
        {
          text: this.$t("Part nomer"),
          value: "part_number",
          sortable: false,
        },
        {
          text: this.$t("Aniqlangan soni"),
          value: "real_stock",
          align: "right",
          sortable: false,
        },
        {
          text: this.$t("Adres"),
          value: "inventory_address_id",
          sortable: false,
        },
        {
          text: this.$t("Kiritivchi"),
          value: "created_employee",
          sortable: false,
        },
        {
          text: this.$t("Tasdiqlovchi"),
          value: "checked_employee",
          sortable: false,
        },
        {
          text: "",
          value: "actions",
          width: 30,
          align: "center",
          sortable: false,
        },
      ];
    },
  },
  methods: {
    updatePage($event) {
      this.getList();
    },
    updatePerPage($event) {
      this.getList();
    },
    getList() {
      this.loading = true;
      axios
        .post(this.$store.state.backend_url + "api/inventory", {
          pagination: this.dataTableOptions,
          filter: this.filter,
        })
        .then((res) => {
          this.items = res.data.data;
          this.from = res.data.from;
          this.server_items_length = res.data.total;
          this.items.map((v) => {
            v.blank_date = moment(v.blank_date).format("DD.MM.YYYY");
            v.created_at = moment(v.created_at).format("DD.MM.YYYY hh:mm");
            v.checked_at = v.checked_at
              ? moment(v.checked_at).format("DD.MM.YYYY hh:mm")
              : v.checked_at;
          });
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    getRef() {
      this.loading = true;
      axios
        .get(this.$store.state.backend_url + "api/inventory/get-ref")
        .then((res) => {
          this.filter_warehouses = res.data.warehouses;
          this.inventoryAddresses = res.data.addresses.map((v) => {
            return { value: v.id, text: v.address_name };
          });
          this.commissions = res.data.commissions.map((v) => {
            return { value: v.id, text: v.commission_number };
          });
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    getProduct() {
      if (this.formInv.part_number && this.formInv.part_number.length == 8) {
        this.loading = true;
        axios
          .get(
            this.$store.state.backend_url +
              "api/inventory/get-part/" +
              this.formInv.part_number +
              "/" +
              this.formInv.warehouse_id
          )
          .then((res) => {
            if (res.data.id) {
              this.formInv.product_name = res.data.product_name;
              document.getElementById("unit_measure").focus();
            } else {
              Swal.fire({
                icon: "error",
                title: "Maxsulot topilmadi!",
              });
              this.formInv.part_number = "";
              document.getElementById("part_number").focus();
            }
            this.loading = false;
          })
          .catch((err) => {
            console.error(err);
            this.errorEmpMessage = false;
            this.loading = false;
          });
      }
    },
    getAddresses() {
      this.loading = true;
      axios
        .get(
          this.$store.state.backend_url +
            "api/inventory/get-address/" +
            this.formInv.warehouse_id
        )
        .then((res) => {
          this.inventoryAddresses = res.data.map((v) => {
            return { value: v.id, text: v.address_name };
          });
          this.loading = false;
        })
        .catch((err) => {
          console.error(err);
          this.loading = false;
        });
    },
    getWarehouses() {
      this.loading = true;
      axios
        .get(
          this.$store.state.backend_url +
            "api/inventory/get-warehouses/" +
            this.formInv.inventory_commission_id
        )
        .then((res) => {
          this.warehouses = res.data;
          this.loading = false;
          document.getElementById("inventory_commission_id").focus();
        })
        .catch((err) => {
          console.error(err);
          this.loading = false;
        });
    },
    getInventoryExcel(page) {
      let new_array = [];
      this.loading = true;
      axios
        .post(this.$store.state.backend_url + "api/inventory", {
          filter: this.filter,
          pagination: {
            page: page,
            itemsPerPage: 1000,
          },
        })
        .then((response) => {
          response.data.data.map((v, index) => {
            new_array.push({
              "№": index + page,
              Ombor: v.warehouse.name,
              Komissiya: v.commission.commission_number,
              "Blanka sanasi": moment(v.blank_date).format("DD.MM.YYYY"),
              "Blanka raqami": v.blank_number,
              "Part NO": v.part_number,
              "Aniqlangan soni": v.real_stock,
              "O'lchov birligi": v.unit_measure,
              Adres: v.address ? v.address.address_name : '',
              Kiritunchi: v.created_employee,
              "Kiritilgan vaqt": moment(v.created_at).format(
                "DD.MM.YYYY hh:mm"
              ),
              Tasdiqlovchi: v.checked_employee,
              "Tasqidlangan vaqt": v.checked_at
                ? moment(v.checked_at).format("DD.MM.YYYY hh:mm")
                : "",
              Tasdiqlovchi: v.checked_employee,
              "Blanka holati": v.blank_status
                ? ''
                : "Bekor qilindi",
            });
          });
          // new_array = response.data.data;

          this.inventory_excel = this.inventory_excel.concat(new_array);
          if (response.data.data.length == 1000) {
            this.getInventoryExcel(++page);
          } else {
            this.loading = false;
            this.downloadExcel = true;
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    editItem(item) {
      this.changeProductsCount(1);
      this.formInv.id = item.id;
      this.formInv.product_count = item.id;
      this.formInv.blank_number = item.blank_number;
      this.formInv.warehouse_id = item.warehouse_id;
      this.formInv.part_number = item.part_number;
      this.formInv.inventory_commission_id = item.inventory_commission_id;
      this.formInv.products[0].real_stock = item.real_stock;
      this.formInv.products[0].inventory_address_id = item.inventory_address_id;
      this.getWarehouses();
      this.getProduct();
    },
    check(item) {
      Swal.fire({
        title: "Ma'lumotlar to'g'riligini tasdiqlaysizmi?",
        text: "Tasdiqlaganingizdan so'ng o'zgartirish imkoniyati yo'q.",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ha, Tasdiqlayman!",
        cancelButtonText: "Yo'q!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .get(
              this.$store.state.backend_url + "api/inventory/check/" + item.id
            )
            .then((res) => {
              Swal.fire(
                "Tasdiqlandi!",
                "Siz ushbu ma'lumotni tasdiqladingiz.",
                "success"
              );
              item = res.data;
              this.items = this.items.map((v) => {
                if (v.id == item.id) return item;
                else return v;
              });
              this.items.map((v) => {
                v.blank_date = moment(v.blank_date).format("DD.MM.YYYY");
                v.created_at = moment(v.created_at).format("DD.MM.YYYY hh:mm");
                v.checked_at = v.checked_at
                  ? moment(v.checked_at).format("DD.MM.YYYY hh:mm")
                  : v.checked_at;
              });
            })
            .catch((err) => {
              Swal.fire("Xatolik!", "Serverda hatolik bor.", "error");
            });
        }
      });
    },
    save() {
      if (this.$refs.inventorCreateForm.validate()) {
        this.loading = true;
        axios
          .post(
            this.$store.state.backend_url + "api/inventory/update",
            this.formInv
          )
          .then((res) => {
            if (res.data.code == 100)
              Swal.fire({
                icon: "error",
                title: res.data.message,
              });
            else {
              this.formInv.id = 0;
              this.formInv.part_number = null;
              this.formInv.product_name = null;
              this.formInv.blank_number = null;
              this.changeProductsCount(this.formInv.products.length);
              // this.formInv.products[0].real_stock = null;
              // this.formInv.products[0].inventory_address_id = null;
              // this.formInv.products[1].real_stock = null;
              // this.formInv.products[1].inventory_address_id = null;
              // this.formInv.products[2].real_stock = null;
              // this.formInv.products[2].inventory_address_id = null;
              // this.formInv.products[3].real_stock = null;
              // this.formInv.products[3].inventory_address_id = null;
              // this.formInv.products[4].real_stock = null;
              // this.formInv.products[4].inventory_address_id = null;
              // this.formInv.products[5].real_stock = null;
              // this.formInv.products[5].inventory_address_id = null;
            }
            this.$refs.inventorCreateForm.resetValidation();
            this.getList();
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
          });
      }
      console.log(this.formInv);
    },
    changeProductsCount(count) {
      this.$refs.inventorCreateForm.resetValidation();
      this.formInv.products = [];
      for (let i = 0; i < count; i++) {
        this.formInv.products.push({
          real_stock: null,
          inventory_address_id: null,
        });
      }
    },
    changeAddress(id) {
      let address = this.inventoryAddresses.filter((v) => {
        if (v.value == id) return v;
      });
      this.inventoryAddresses = this.inventoryAddresses.filter((v) => {
        if (v.value != id) return v;
      });
      this.inventoryAddresses = address.concat(this.inventoryAddresses);

      console.log(address);
    },
    changeStatusItem(item) {
      Swal.fire({
        title: "Blankani bekor qilish?",
        text:
          "Ushbu Blanka raqamiga tegishli barcha ma'lumotlar bekor qilinadi.",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ha!",
        cancelButtonText: "Yo'q!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post(
              this.$store.state.backend_url + "api/inventory/change-status",
              { blank_number: item.blank_number }
            )
            .then((res) => {
              if (res.data == 200) {
                Swal.fire("Bekor qilindi!", "", "success");
                this.getList();
              } else {
                Swal.fire("Ma'lumot topilmadi!", "", "error");
              }
            })
            .catch((err) => {
              Swal.fire("Xatolik!", "Serverda hatolik bor.", "error");
            });
        }
      });
    },
  },
  mounted() {
    this.getRef();
    this.getList();
  },
};
</script>
<style scoped>
.v-content .v-card.v-sheet.theme--light {
  background-color: #ffffff !important;
  border-left: 5px solid red !important;
}
.v-item--active {
  background-color: #fff !important;
}
.dense {
  padding: 0px;
  height: 10px !important;
}

.dense .v-text-field__details {
  display: none !important;
}

.dense .v-text-field {
  padding: 0px;
  margin: 0px;
}

.dense div div div {
  margin-bottom: 0px !important;
}
</style>
